//
// _quill-editor.scss
//


.ql-editor {
    text-align: left;
    ol, ul {
        padding-left: 1.5em;
        padding-right: 0;
        li {
            &:not(.ql-direction-rtl) {
                padding-left: 1.5em;
                padding-right: 0;
            }
            &:before {
                margin-left: -1.5em !important;
                margin-right: 0.3em !important;
            }
        }
    }
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: 0;
    left: auto; 
}

// .ql-snow {
//     .ql-picker {
//         &:not(.ql-color-picker):not(.ql-icon-picker) {
//             svg {
//                 right: 0;
//             }
//         }
//     }
// }

.ql-container {
    font-family: $font-family-base;

    &.ql-snow {
        border-color: $input-border-color;
    }
}

.ql-bubble {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
}

.ql-toolbar {
    font-family: $font-family-base !important;
    span {
        outline: none !important;
        color: $dropdown-link-color;

        &:hover {
            color: $primary !important;
        }
    }

    &.ql-snow {
        border-color: $input-border-color;

        .ql-picker.ql-expanded {
            .ql-picker-label {
                border-color: transparent;
            }
        }
    }
}

.ql-snow {
    .ql-stroke,
    .ql-script,
    .ql-strike svg {
        stroke: $dropdown-link-color;
    }
    .ql-fill {
        fill: $dropdown-link-color;
    }
}

.ql-snow {
    .ql-picker-options {
        background-color: $dropdown-bg;
        border-color: $dropdown-border-color !important;
    }
}

